import React from 'react';
import { Typography } from 'antd';
import { ModalTitle } from './servicesConfigs';
import { ErrorData, Record, LoadingData } from './Services';
import { NewEditTypeContent } from './NewEditTypeContent';

const { Text } = Typography;

interface ModalContentProps {
    modalData: Record;
    setModalData(data: Record): void;
    modalTitle: string;
    error: ErrorData;
    setError(error: ErrorData): void;
    currentPriceListType: string;
    setImageId(imageId: string): void;
    loading: LoadingData;
    setLoading(loading: LoadingData): void;
    providerTypesList: string[];
}

export const ModalContent: React.FC<ModalContentProps> = (
    {
        modalData,
        setModalData,
        modalTitle,
        error,
        setError,
        currentPriceListType,
        setImageId,
        loading,
        setLoading,
        providerTypesList
    }) => {

    switch (modalTitle) {
        case ModalTitle.NEW_SERVICE_TYPE:
        case ModalTitle.EDIT_SERVICE_TYPE:
        case ModalTitle.EDIT_PROVIDER_TYPE:
        case ModalTitle.NEW_PROVIDER_TYPE:
            return <NewEditTypeContent
                modalData={modalData}
                setModalData={setModalData}
                error={error}
                setError={setError}
                currentPriceListType={currentPriceListType}
                setImageId={setImageId}
                modalTitle={modalTitle}
                loading={loading}
                setLoading={setLoading}
                providerTypesList={providerTypesList}
            />
        case ModalTitle.DELETE_SERVICE_TYPE:
        case ModalTitle.DELETE_PROVIDER_TYPE:
            return <DeleteTypeContent modalTitle={modalTitle}/>
        default:
            return <></>
    }
}

const DeleteTypeContent = ({ modalTitle }: {modalTitle: string}) => {
    return (
        <Text>
            {`Are you sure, you want to delete a ${modalTitle === ModalTitle.DELETE_SERVICE_TYPE ? 'service': 'provider type'}?`}
        </Text>
    )
}

