import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { PromoCodeRequest, UpdatedContent } from "./campaingTypes";
import { styles } from "./styles";

const { RangePicker } = DatePicker;

interface RangePickerCustomProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    updatedContent: UpdatedContent[] | UpdatedContent;
    disabled: boolean;
}

export const RangePickerCustom: React.FC<RangePickerCustomProps> = ({ updatedContent, modalContent, setModalContent, disabled }) => {
    const content: UpdatedContent = typeof updatedContent === 'object' ? updatedContent[0] : updatedContent;
    const handleDisabledDate = (date: any) => {
        if (moment(date) < moment(new Date())) {
            return true
        }
        return false
    }

    const handleDateChange = (dates: any) => {
        if(dates) {
            typeof updatedContent === 'object'?
                setModalContent({
                    ...modalContent,
                    [updatedContent[0] as UpdatedContent]: {
                        ...modalContent[updatedContent[0] as UpdatedContent],
                        startDate: dates[0]?.format('YYYY-MM-DD[T]HH:MM:ss'),
                        endDate: dates[1].format('YYYY-MM-DD[T]HH:MM:ss'),
                    },
                    [updatedContent[1] as UpdatedContent]: {
                        ...modalContent[updatedContent[1] as UpdatedContent],
                        startDate: dates[0]?.format('YYYY-MM-DD[T]HH:MM:ss'),
                        endDate: dates[1].format('YYYY-MM-DD[T]HH:MM:ss'),
                    }
                }):
                setModalContent({
                    ...modalContent,
                    [updatedContent as UpdatedContent]: {
                        ...modalContent[updatedContent as UpdatedContent],
                        startDate: dates[0]?.format('YYYY-MM-DD[T]HH:MM:ss'),
                        endDate: dates[1].format('YYYY-MM-DD[T]HH:MM:ss'),
                    }
                })
        }
    }

    return (
        <RangePicker
            value={modalContent[content as UpdatedContent]?.startDate ? [moment(modalContent[content as UpdatedContent]?.startDate), moment(modalContent[content as UpdatedContent]?.endDate)] : null}
            disabledDate={handleDisabledDate}
            onChange={handleDateChange}
            style={styles.modalRowItem}
            disabled={disabled}
        />
    )
}