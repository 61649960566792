import { authorisedRequest } from './authorisedRequest';

export enum Deposit {
  Credit= 'CREDIT',
  Debit = 'DEBIT',
}
interface DepositPayload {
  amount: number;
  depositType: Deposit
}
export const fetchCustomerDetails = (id: string) => {
  return authorisedRequest(`/admin/customers/${id}`);
}

export const fetchCustomerPaymentMethods = (id: string) => {
  return authorisedRequest(`/admin/customers/${id}/payment-methods`);
}

export const exportCustomersData = (filterConfigs: any) => {
  let filterQuery = '?';
  if (filterConfigs) {
    for (let [key, value] of Object.entries(filterConfigs)) {
      filterQuery += `&${key}=${value}`;
    }
  }
  return authorisedRequest(`/export/customers${filterQuery}`)
}

export const updateCustomerData = (customerId: string, payload: any) => {
  return authorisedRequest(`/admin/customers/${customerId}`, 'PUT', payload)
}

export const updateCustomerDeposit = (customerId: string, depositPayload: DepositPayload) => {
  return authorisedRequest(`/admin/customers/${customerId}/deposits`, 'POST', depositPayload)
}

export const fetchDeposits = (customerId: string) => {
  return authorisedRequest(`admin/customers/${customerId}/deposits`);
}
